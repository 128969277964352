/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 15/06/2021.
 */
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

type Props = {
  size?: number
  label?: string
}

export const Spinner = ({ size = 50, label }: Props) => {
  return (
    <div className="d-flex flex-column align-items-center mt-4">
      <Spin indicator={<LoadingOutlined style={{ fontSize: size }} spin />} />
      <p className="mt-4 text-center">{label}</p>
    </div>
  )
}
