import './styles/style.scss'
import './styles/ant-compiled.css'

import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Spinner } from './components/Spinner/Spinner'
import Amplify from 'aws-amplify'

import awsconfig from './aws-exports'
Amplify.configure(awsconfig)

const App = React.lazy(() => import('./App'))

if (process.env.NODE_ENV !== 'production') {
  //
  // debug indication of which breakpoint which is active
  //
  //@ts-ignore
  import('./styles/size-debug.scss').then(() => {})
}

//setupSentry()

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<Spinner></Spinner>}>
      <App />
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
)

//ReactDOM.render(<App />, document.getElementById('root'))
